@import "../../../styles/Global.scss";

.spinnerContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .MuiCircularProgress-colorPrimary {
    color: $blue;
  }
}
