.productSearchBar {
  margin: 2em 0;
  .productSearchForm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    gap: 2em;
    .formFields {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 1em;
      .field {
        width: 254px;
      }
    }
  }
}
