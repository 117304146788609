.consumptionListContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.itemConsumptionList {
  max-width: 280px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
