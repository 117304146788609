@import "../../../../../../styles/Global.scss";

.startDateField {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  max-width: 400px;
  .staysFormLabel {
    font: $subtitle4;
    color: $blue;
  }
}
