@import "../../../../styles/Global.scss";

.productsTable {
  margin-top: 2em;
}

.MuiList-root {
  .MuiIconButton-label {
    .MuiSvgIcon-root {
      color: $blue;
    }
  }
}
.MuiTableCell-root.MuiTableCell-footer {
  border-bottom: none;
}
.MuiOutlinedInput-root {
  height: 36px;
}
.createStayButton {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
}

.MuiBox-root {
  span {
    button {
      background-color: $blue;
      color: white;
      border-radius: 50px;
      width: 32px;
      height: 32px;
      margin: 0 4px;
    }
    button.Mui-disabled {
      background-color: $blue;
      color: white;
    }
    button:hover {
      background-color: $blue;
    }
  }
}

/* td.css-1ex1afd-MuiTableCell-root {
  border: none;
} */
