.consumptionsSearchBar {
  margin: 2em 0;
  .consumptionsSearchForm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    .formFields {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 1em;
      .apartmentField {
        width: 280px;
      }
    }
  }
}
