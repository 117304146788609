@import "../../../../styles/Global.scss";

.addButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 32px;
  background: $green 0% 0% no-repeat padding-box;
  border-style: none;
  .icon {
    color: $white;
  }
}
.addButton:hover {
  cursor: pointer;
}
