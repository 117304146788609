@import "../../../../../styles/Global.scss";

.productPriceField {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 30%;
}
.productFormLabel {
  font: $subtitle4;
  color: $blue;
}
