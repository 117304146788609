@import "../../../../../styles/Global.scss";

.productNameField {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 70%;
}
.productFormLabel {
  font: $subtitle4;
  color: $blue;
}
