@import "../../../../../styles/Global.scss";

.typeOfBenefitField {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  .productsSearchBarLabel {
    font: $subtitle4;
    color: $blue;
  }
}
