@import "../../../../../styles/Global.scss";

.addedBeforeDateField {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  max-width: 400px;
  .formLabel {
    font: $subtitle4;
    color: $blue;
  }
}
