@import "../../../styles/Global.scss";

.MuiTableCell-root.css-11w94w9-MuiTableCell-root {
  border: none;
}

.MuiCheckbox-root.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: $blue;
}
