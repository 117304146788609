@import "../../../../../../styles/Global.scss";

.guestQRCodeField {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  max-width: 400px;
}
.guestFormLabel {
  font: $subtitle4;
  color: $blue;
}
