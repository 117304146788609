.stays {
  position: relative;
  .staysContainer {
    display: flex;
    flex-direction: column;
  }
  .spinner {
    position: relative;
    margin-top: 5em;
  }
}

.floatingButton {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
}
