@import "../../../../../../../styles/Global.scss";

.benefitFieldContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-content: center;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  .guestBenefitFieldLabel {
    grid-column: 1/4;
    font: $caption;
    color: $blue;
    width: 80%;
  }
  .guestBenefitField {
    grid-column: 4/5;
    width: 20%;
  }
}
