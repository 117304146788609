@import "../../../../styles/Global.scss";

.MuiList-root {
  .MuiIconButton-label {
    .MuiSvgIcon-root {
      color: $blue;
    }
  }
}
.MuiTableCell-root.MuiTableCell-footer {
  border-bottom: none;
}
/* .tableContainer {
  position: relative;
} */
.createStayButton {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
}

.MuiBox-root {
  span {
    button {
      background-color: $blue;
      color: white;
      border-radius: 50px;
      width: 32px;
      height: 32px;
      margin: 0 4px;
    }
    button.Mui-disabled {
      background-color: $blue;
      color: white;
    }
    button:hover {
      background-color: $blue;
    }
  }
}
