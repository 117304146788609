@import "../../../../styles/colors";

.headerWrapper {
  background-color: $blue;
  .headerContainer {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 160px;
    width: 100%;
    .logoContainer {
      width: 136px;
    }
  }
}
