@import "../../../../../styles/Global.scss";

.editProductPopup {
  height: 20px;
}

.editProductButton {
  cursor: pointer;
  border: none;
  background: transparent;
  color: $blue;
  padding: 0;
  .icon {
    font-size: 1.2rem;
  }
}
.editProductButton:visited {
  color: $blue;
}
