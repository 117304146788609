@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$heading1: normal normal 400 28px/36px Poppins;
$subtitle1: normal normal 500 24px/32px Poppins;
$subtitle2: normal normal 400 22px/30px Poppins;
$subtitle3: normal normal 400 20px/27px Poppins;
$subtitle4: normal normal normal 16px/16px Poppins;
$body1: normal normal 500 17px/22px Poppins;
$body2: normal normal 400 17px/22px Poppins;
$caption: normal normal 400 14px/16px Poppins;
$captionItalic: italic normal 400 12px/16px Poppins;
$caption2: normal normal 500 12px/12px Poppins;
$tag: normal normal 400 12px/16px Poppins;
// no usaria tantas variantes para un mismo tamaño de letra
$body3: normal normal 500 17px/55px Poppins;
$button: normal normal 400 18px/28px Poppins;
$button2: normal normal 400 17px/28px Poppins;
$button3: normal normal 400 16px/22px Poppins;
