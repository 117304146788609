@import "../../../../styles/Global.scss";

.buttonMiddle {
  background-color: $green;
  width: 192px;
  height: 64px;
  border-style: none;
  border-radius: 30px;
  padding: 0.5em;
  cursor: pointer;
  p {
    font: $button3;
    color: $white;
  }
}
.disabled {
  background-color: $grayLightest;
  cursor: auto;
  p {
    color: $grayLight;
  }
}
