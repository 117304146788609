@import "../../../../../styles/Global.scss";

.viewBenefitsButton {
  color: #817185;
  cursor: pointer;
  border: none;
  background: transparent;
  width: 120px;
}

.benefitsPopupContent {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  position: relative;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  .floatingCloseButton {
    position: fixed;
    top: 1em;
    right: 1em;
    border: none;
    background-color: transparent;
    cursor: pointer;
    .closeIcon {
      font-size: 2.5rem;
      color: $gray;
    }
  }

  .popupHeader {
    padding: 0.5em 2.5em;
    display: inline-block;
    top: 0;
    position: sticky;
    background-color: $white;
    .popupTitle {
      font: $subtitle2;
      color: $blue;
    }
  }
}

.benefitsPopupContent::-webkit-scrollbar {
  width: 10px;
}

.benefitsPopupContent::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 3px;
}

.benefitsPopupContent::-webkit-scrollbar-thumb:hover {
  background: transparent;
  box-shadow: 0 0 2px 1px transparent;
}

.benefitsPopupContent::-webkit-scrollbar-thumb:active {
  background-color: transparent;
}

.benefitsPopupContent::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 3px;
}
