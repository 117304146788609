@import "../../../../styles/Global.scss";

.formWrapper {
  max-width: 600px;
  margin: 0 auto;
  padding-top: 60px;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  .authInputField {
    background: transparent;
  }
}

.formTitlesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding-bottom: 32px;
  .formTitle {
    font: $heading1;
    color: $blue;
  }
  .formSubtitle {
    font: $subtitle3;
    color: $blue;
  }
}
