@import "../../../../../styles//Global.scss";

.deleteStayButton {
  color: $blue;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  border: none;
  background: transparent;
  .icon {
    font-size: 1.2rem;
  }
}
