@import "../../../../../../styles/Global.scss";

.button {
  border: none;
  background-color: transparent;
  .buttonText {
    font: $button;
    color: $white;
    cursor: pointer;
  }
}
