@import "../../../../styles/Global.scss";

.productFormContainer {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  .productForm {
    display: flex;
    flex-direction: column;
    gap: 3.5em;
    .productsFormTitle {
      font: $subtitle2;
      color: $blue;
      padding-top: 1em;
    }
    .productFields {
      display: flex;
      flex-direction: column;
      gap: 2em;
      .productAndPriceContainer {
        display: flex;
        flex-direction: row;
        gap: 2em;
        align-items: center;
      }
      .submitButtonContainer {
        margin-top: 4em;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
  }
}
