@import "../../../../styles/Global.scss";

.productsCreationEditionPopupContent {
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  position: relative;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  .floatingCloseButton {
    position: fixed;
    top: 1em;
    right: 1em;
    cursor: pointer;
    border: none;
    background: transparent;
    color: $blue;
    .closeIcon {
      font-size: 2.5rem;
      color: $gray;
    }
  }
}
