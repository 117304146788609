@import "../../../../../styles/Global.scss";

.headerToolbar {
  display: flex;
  flex-direction: row;
  gap: 2em;
}
.button {
  border: none;
  background-color: transparent;
  .buttonText {
    font: $button;
    color: $white;
    cursor: pointer;
  }
}
