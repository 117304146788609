@import "../../../../../styles/Global.scss";

.benefitStatisticsCard {
  border: 2px solid $blue;
  border-radius: 8px;
  height: 88px;
  flex-grow: 1;
  .benefitName {
    padding: 8px 0;
    font: $body1;
    color: $blue;
    text-align: center;
  }
  .quantitiesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    padding: 0 0.5em 0.5em;
    .nameQuantity {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.25em;
      .valueTitle {
        font: $caption2;
        color: $gray;
      }
      .value {
        font: $body1;
        color: $blue;
      }
    }
  }
}
