@import "../../../../../styles/Global.scss";

.accountStateField {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  .staysFormLabel {
    font: $subtitle4;
    color: $blue;
  }
}
