@import "../../../../styles/Global.scss";

.buttonSmall {
  background-color: $green;
  width: 128px;
  height: 42px;
  border-style: none;
  border-radius: 27px;
  cursor: pointer;
  p {
    font: $button2;
    color: $white;
  }
}

.disabled {
  background-color: $grayLightest;
  cursor: auto;
  p {
    color: $grayLight;
  }
}
