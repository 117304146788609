@import "../../../../../../../styles/Global.scss";
.deleteUserButton {
  cursor: pointer;
  border: none;
  background: transparent;
  color: $gray;
  padding: 0;
  .icon {
    font-size: 1rem;
  }
}
